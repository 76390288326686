html {
  height: 100%;
  font-size: 10px;
}

body {
  font-family: 'Gilroy', Arial, sans-serif;
  display: flex;
  flex-direction: column;
  color: white;
  touch-action: none;
  min-height: var(--vh100);
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  > div {
    display: flex;
    flex-direction: column;
  }
}